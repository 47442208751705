
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);
    const login = Yup.object().shape({
      username: Yup.string().required().label("username"),
      password: Yup.string().min(6).required().label("Password"),
    });

    const onSubmitLogin = (values) => {
      store.dispatch(Actions.LOGOUT);
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      setTimeout(() => {
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            if (store.getters.currentUser.id) {
              if (store.getters.currentUser.id === 1) {
                router.push({ name: "dashboard" });
              } else {
                console.log(store.getters.currentUser.id);
                ApiService.get(
                  "entityorganogram/info/" + store.getters.currentUser.id
                )
                  .then(({ data }) => {
                    console.log(data);
                    let link =
                      data.data[0].entity_type_role.menu_action_permissions[0]
                        .menu.link;
                    router.push({ path: link });
                  })
                  .catch(({ response }) => {
                    console.log(response);
                  });
              }
            }
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again",
              customClass: {
                confirmButton: "btn fw-bold btn-danger",
              },
            });
          });
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };
    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
